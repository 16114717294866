exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-academy-js": () => import("./../../../src/pages/academy.js" /* webpackChunkName: "component---src-pages-academy-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-eyeliner-js": () => import("./../../../src/pages/eyeliner.js" /* webpackChunkName: "component---src-pages-eyeliner-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-microscalppigmentation-js": () => import("./../../../src/pages/microscalppigmentation.js" /* webpackChunkName: "component---src-pages-microscalppigmentation-js" */),
  "component---src-pages-powderbrows-js": () => import("./../../../src/pages/powderbrows.js" /* webpackChunkName: "component---src-pages-powderbrows-js" */),
  "component---src-pages-pricelist-js": () => import("./../../../src/pages/pricelist.js" /* webpackChunkName: "component---src-pages-pricelist-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */)
}

